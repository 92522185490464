import { EnvironmentFilled } from '@ant-design/icons';
import { Alert, Space } from 'antd';
import React from 'react';

const Address = ({ address }) => {
    return (
        <>
            <div style={{ padding : '0 24px'}}>
                <div style={{textAlign:'start', background:'var(--secondary-button-color)', borderRadius:8, marginTop:12,}}>
                    <div style={{padding: 12,}}>
                        <Space>
                            <EnvironmentFilled style={{ color : 'var(--secondary-text-color)', }} />
                            <div>
                                <span
                                className='ticket-label ticket-title h1'
                                style={{ fontSize:12, color:'var(--secondary-text-color)', whiteSpace : 'wrap',}}
                                >
                                    {address}
                                </span>
                            </div>
                        </Space>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Address;