import axios from 'axios';
import serviceHelper from '../../helpers/functions/service.helper';

async function fetchPazzport(pazzport){
    const pazzportResponse = await axios.get(`integration/kidzoft/pazzport/${pazzport}`, { withCredentials : true });
    return pazzportResponse?.data;
}

async function updateVisitDate(params){
    const updateVisitDateResponse = await axios.post(`integration/kidzoft/updateDate`, params, { withCredentials : true, });
    return updateVisitDateResponse?.data;
}

export default {
    fetchPazzport,
    updateVisitDate,
}