import { Alert, Input } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import kidzaniaService from '../../../services/integration/kidzania.service';
import { CheckCircleFilled, CloseCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { set_ticket_saveable } from '../../../actions/ticket.action';
import EntranceMotion from '../../../helpers/components/entrance_motion';

const IntegrationPazzport = ({field, form}) => {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        loading : false,
        input : null,
        
        pazzport : null,
        errors : [],
    })
    const [timer, setTimer] = useState(null);

    const fetchPazzport = async (input) => {
        dispatch(set_ticket_saveable(false));
        setState(state => ({...state, loading : true, }));
        const pazzportResponse = await kidzaniaService?.fetchPazzport(input);
        
        const { pazzport } = pazzportResponse;
        let errors = [];
        if(pazzport){
            dispatch(set_ticket_saveable(true));
        }else{
            errors.push({ type : 'not_found', })
            setState(state => ({...state, errors : errors }));
        }
        
        setState(state => ({...state, loading : false, pazzport : pazzport, errors : errors,  }));
    }

    const doneTyping = async (input) => {
        setState(state => ({...state, input : input}))
        form.setFieldsValue({ pazzport : input, })
        clearTimeout(timer);
        const newTimer = setTimeout(() => {
            if(input){
                fetchPazzport(input);
            }else{
                dispatch(set_ticket_saveable(true));
                setState(state => ({...state, pazzport : null,}));
            }
        }, 300);
        setTimer(newTimer);
    }

    useEffect(() => {
        const { pazzport } = form?.getFieldsValue() ?? {};
        pazzport && fetchPazzport(pazzport);
        setState(state => ({...state, input : pazzport}))
    }, [form])
    
    return (
        <>
            <Input
            suffix={
                state?.loading ? 
                (<><LoadingOutlined /></>)
                :
                (
                    <>
                        {
                            state?.input && (
                                <>
                                    {
                                        state?.pazzport ? 
                                        (
                                            <>
                                                <CheckCircleFilled style={{color:'#95de64'}} />
                                            </>
                                        )
                                        :
                                        (
                                            <>
                                                <CloseCircleFilled style={{color:'#ff7875'}} />
                                            </>
                                        )
                                    }
                                </>
                            )
                            
                        }
                    </>
                )
            } 
            value={state?.input} onChange={(e) => doneTyping(e.target.value)}
            placeholder={field?.placeholder}
            style={{border:'2px solid #cbd5e0'}}
            />

            {
                state?.pazzport &&
                (
                    <>
                        <div style={{marginTop:12,}}>
                            <EntranceMotion>
                                <Alert
                                style={{padding : 6, border : 'none', }}
                                message={(
                                    <>
                                        <span style={{fontSize:14, color : 'var(--secondary-text-color)'}}>Welcome Back</span>
                                    </>
                                )}
                                description={`${state?.pazzport?.name} ${state?.pazzport?.lastName}`}
                                />
                            </EntranceMotion>
                        </div>
                    </>
                )
            }
        </>
    );
}

export default IntegrationPazzport;