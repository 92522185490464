import React from 'react';
import globalHelper from '../../../helpers/functions/global.helper';
import { Alert, Avatar, Form, Space } from 'antd';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ExpiredDetailTicketUser = ({ ticket_user, ticket_unique_no }) => {
    return (
        <>
            <div className='save-ticket-user-content'>
                <div>
                    <EntranceMotion delay={0.1}>
                        <div>
                            <Alert 
                            style={{border:'none'}}
                            message={<span style={{fontWeight:500,}}>This ticket is expired</span>}
                            // description="This ticket has been expired, "
                            type="error"/>
                        </div>
                    </EntranceMotion>
                </div>
                <div style={{marginTop:24,}}>
                    <div>
                        <span
                        className='ticket-label ticket-title h1'
                        style={{
                            fontWeight:'bold',
                            fontSize: globalHelper?.isMobileScreen() ? 20 : 24,

                        }}
                        >
                            {`Upload Selfie`}
                        </span>
                    </div>

                    <div style={{marginTop:40}}>
                        <EntranceMotion initialY={-100} duration={1}>
                            <div className="border-circle" style={{border : '2px solid var(--main-color)', borderRadius:'50%', padding:12, width:'fit-content', margin:'auto'}}>
                                <Avatar size={140} src={
                                    <LazyLoadImage
                                    src={ticket_user?.image_url ?? "/cibanners/empty-user.png"}
                                    />
                                    
                                } 
                                />
                            </div>
                        </EntranceMotion>
                    </div>

                    <div>
                        {
                            (ticket_user?.ticket?.is_open_date) && ticket_user?.open_date && 
                            (
                                <>
                                    <div style={{marginTop:12,}}>
                                        <span style={{color:'#6f7287', fontWeight:500}}>
                                            <Space wrap size={2}>
                                                <span>Visit Date:</span>
                                                <span>{ticket_user?.open_date}</span>
                                            </Space>
                                        </span>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default ExpiredDetailTicketUser;