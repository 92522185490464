import React, { useEffect, useState } from 'react';
import ticketService from '../../../services/ticketing/ticket.service';
import { Alert, Button, Col, Divider, Drawer, Image, Row } from 'antd';
import TicketSummary from './ticket_summary';
import { LoadingOutlined } from '@ant-design/icons';
import MobileBottomLayout from '../mobiles/mobile_bottom_layout';
import { useDispatch, useSelector } from 'react-redux';
import { set_visit_date, set_date_park } from '../../../actions/date.action';
import OrderSummary from './order_summary';
import DateSelect from './date_select';
import MobileUserSummary from '../mobiles/mobile_user_summary';
import DateCheckoutButton from './date_checkout_button';
import moment from 'moment';
import paymentService from '../../../services/payment/payment.service';
import parkService from '../../../services/config/park.service';
import { set_purchase_detail } from '../../../actions/purchase.action';
import SimpleNotFoundComponent from '../../../helpers/components/simple_not_found.component';
import PreferenceSelect from './preference_select';

const DateLayout = ({ ticket_unique_no }) => {
    const dispatch = useDispatch();
    const dateRedux = useSelector(state => state?.date);
    const [state, setState] = useState({
        loading : true,
        ticket : null,
        drawer : false,

        // date advance day
        change_date_advance_day : null,

        // checked in
        is_checked_in : false,

        // for preference purpose
        park_applicable_ticket_type_id : null,
    })

    const fetchTicketSummary = async () => {
        setState(state => ({...state, loading : true}));
        //get complete payment status
        const paymentStatusResponse = await paymentService?.getPaymentStatus();
        const complete_payment_status = paymentStatusResponse?.payment_statuses?.filter(x => x.is_payment_complete);
        const payment_status_ids = (complete_payment_status?.map(x => x.payment_status_id));
        
        const ticketResponse = await ticketService.getTicket({ ticket_unique_no, payment_status_ids });
        const ticket = ticketResponse?.tickets?.length > 0 && ticketResponse?.tickets[0];

        const parkApplicableTicketTypeResponse = await parkService?.getParkApplicableTicketTypes({ park_id : ticket?.park_id, ticket_type_id : ticket?.ticket_type_id, });
        const park_applicable_ticket_type = parkApplicableTicketTypeResponse?.park_applicable_ticket_types?.length > 0 ? parkApplicableTicketTypeResponse?.park_applicable_ticket_types[0] : null;
        const change_date_advance_day = park_applicable_ticket_type?.change_date_advance_day;

        // get park
        const parkResponse = await parkService?.getPark({
            park_code : ticket?.park?.park_code,
        });
        const { parks } = parkResponse;

        // fetch is checked in 
        const checkInResponse = await ticketService?.checkIsCheckedIn(ticket?.ticket_id);
        const is_checked_in = checkInResponse?.is_checked_in;
        setTimeout(() => {
            //set default new date
            dispatch(set_visit_date(ticket?.visit_date));
            dispatch(set_purchase_detail({ ticket_type_id : ticket?.ticket_type_id, })); // to hide on base camp date
            dispatch(set_date_park(parks));
            setState(state => ({...state, loading : false, ticket : ticket, change_date_advance_day : change_date_advance_day, is_checked_in : is_checked_in, park_applicable_ticket_type_id : park_applicable_ticket_type?.park_applicable_ticket_type_id, }));
        }, 200);
    }

    const openDrawer = (is_open) => {
        setState(state => ({...state, drawer : is_open,}))
    }

    useEffect(() => {
        fetchTicketSummary();
    }, [])

    return (
        <>
            <div className='mobile-padding-24'>
                {
                    state?.loading ?
                    (
                        <>
                            <div>
                                <LoadingOutlined style={{fontSize:20}} />
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            {
                                state?.ticket ?
                                (
                                    <>
                                        <div style={{textAlign:'left'}}>
                                            <div style={{display:'flex', flexFlow:'wrap', alignItems:'center'}}>
                                                <div>
                                                    <span
                                                    className='ticket-label ticket-title h1'
                                                    style={{
                                                        fontWeight:'bold',
                                                        fontSize: '1.5rem'
                                                    }}
                                                    >
                                                        Change Visit Date <span style={{color:'var(--main-color)'}}>{state?.ticket?.park?.name}</span>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{marginTop:24,}}>
                                            <Row gutter={[40, 20]}>
                                                <Col span={10} xs={24} md={12} lg={10}>
                                                    <TicketSummary ticket={state?.ticket} />
                                                </Col>
                                                {
                                                    (state?.ticket?.visit_date && !state?.ticket?.is_open_date) ? 
                                                    (
                                                        <>
                                                            <Col span={14} xs={0} md={12} lg={14}>
                                                            {
                                                                state?.is_checked_in ?
                                                                (
                                                                    <>
                                                                        <Alert 
                                                                        style={{border:'none', background : 'var(--secondary-button-color)'}}
                                                                        message={<span style={{fontWeight:500,}}>Ticket have been checked in</span>}
                                                                        // description="This ticket has been expired, "
                                                                        />
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                    {
                                                                        moment(state?.ticket?.visit_date)?.startOf('days')?.diff(moment()?.startOf('days'), 'days') >= state?.change_date_advance_day ?
                                                                        (
                                                                            <>
                                                                                <div>
                                                                                    <div>
                                                                                        <DateSelect park={state?.ticket?.park} />
                                                                                    </div>
                                                                                    <div>
                                                                                        <PreferenceSelect park_applicable_ticket_type_id={state?.park_applicable_ticket_type_id} ticket={state?.ticket} />
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </>
                                                                        )
                                                                        :
                                                                        (
                                                                            <>
                                                                                <Alert 
                                                                                style={{border:'none'}}
                                                                                message={<span style={{fontWeight:500,}}>Ticket date cannot be changed</span>}
                                                                                // description="This ticket has been expired, "
                                                                                type="error"/>
                                                                            </>
                                                                        )
                                                                    }
                                                                    {
                                                                        moment(state?.ticket?.visit_date)?.startOf('days')?.diff(moment()?.startOf('days'), 'days') >= state?.change_date_advance_day &&
                                                                        (
                                                                            <>
                                                                                <Col span={24} xs={0} md={24} lg={24}>
                                                                                    <div style={{display:'flex', marginTop:24,}}>
                                                                                        <div style={{marginLeft:'auto'}}>
                                                                                            <Button
                                                                                            type='primary' 
                                                                                            size='large' 
                                                                                            style={{color:'var(--main-text-color)', minWidth:200, fontWeight:500,}}
                                                                                            onClick={() => openDrawer(true)}
                                                                                            >
                                                                                                Continue
                                                                                            </Button>
                                                                                        </div>
                                                                                    </div>
                                                                                </Col>
                                                                            </>
                                                                        )
                                                                    }
                                                                    </>
                                                                )
                                                            }
                                                            </Col>
                                                        </>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <Col span={14} xs={0} md={12} lg={14}>
                                                                <Alert 
                                                                style={{border:'none'}}
                                                                message={<span style={{fontWeight:500,}}>This ticket is an open date ticket</span>}
                                                                />
                                                            </Col>
                                                        </>
                                                    )
                                                }
                                            </Row>
                                        </div>
                                    </>
                                )
                                :
                                (
                                    <>
                                        <SimpleNotFoundComponent
                                        redirect_url={'/date/search'}
                                        redirect_name={`Search Ticket Again`}
                                        title={`Transaction Not Found`}
                                        description={`${ticket_unique_no} does not found in the records`}
                                        />
                                    </>
                                )
                            }
                        </>
                    )
                }
                
                
            </div>

            <Drawer
            open={state?.drawer}
            onClose={() => openDrawer(false)}
            width={600}
            bodyStyle={{padding:0,}}
            >
                <div style={{padding:12,}}>
                    <OrderSummary ticket={state?.ticket} visit_date={dateRedux?.visit_date} />

                    <div style={{padding:24,}}>
                        <DateCheckoutButton ticket_unique_no={state?.ticket?.unique_no} />
                    </div>
                </div>
                {/* <MobileUserSummary ticket={state?.ticket} visit_date={dateRedux.visit_date} /> */}
            </Drawer>

            {/* mobile view */}
            <MobileBottomLayout
            change_date_advance_day={state?.change_date_advance_day}
            ticket={state?.ticket}
            loading={state?.loading}
            is_checked_in={state?.is_checked_in}
            is_open_date={state?.ticket?.is_open_date}
            park_applicable_ticket_type_id={state?.park_applicable_ticket_type_id}
            />
        </>
    );
}

export default DateLayout;