import { Modal } from 'antd';
import React, { useEffect, useState, } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { upload_image } from '../../actions/ticket.action';
import ContainerComponent from '../../helpers/components/container.component';
import DetailLayout from './components/detail_layout';
// import ConfigTutorial from './components/config_tutorial';


const TicketUserFaceDetail = () => {
    const dispatch = useDispatch();
    const { ticket_user_id } = useParams();
    const [state, setState] = useState({
        modal : false,
    })


    //get ticket number
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    // Access individual query parameters
    const ticket_unique_no = queryParams.get('ticket');

    //have to use pass functions here
    //or else redux waste resource
    const changeModal = (open) => {
        const is_opened = localStorage.getItem("face_config_guide");
        open = is_opened ? false : open;
        setState(state => ({...state, modal : open}));

        if(open){
            //set local storage
            localStorage.setItem("face_config_guide", true);
        }
    }

    useEffect(() => {
        changeModal(true);
        // setState(state => ({...state, modal : true}));
        return () => {
            dispatch(upload_image(null));
        }
    }, [])

    return (
        <>
            <div className='ticket-detail-wrapper'>
                <ContainerComponent span={14} top={20} bottom={20}>
                    <DetailLayout ticket_user_id={ticket_user_id} ticket_unique_no={ticket_unique_no} />
                </ContainerComponent>
            </div>
        </>
    );
}

export default TicketUserFaceDetail;