import React from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ContainerComponent from '../../helpers/components/container.component';
import './face_config.css';
import './ticket.css';
import ListLayout from './components/list_layout';

const FaceConfig = () => {
    const { ticket_id } = useParams();

    //get query params
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page');

    return (
        <>
            <ContainerComponent span={16} top={20} bottom={20}>
                <ListLayout ticket_unique_no={ticket_id} page={page} />
            </ContainerComponent>
        </>
    );
}

export default FaceConfig;