import { Layout } from 'antd';
import React, { useRef } from 'react';
import MasterHeader from './master_header';
import globalHelper from '../../helpers/functions/global.helper';

const { Header, Content, Sider, Footer } = Layout;
const MasterLayout = ({children}) => {
    const contentRef = useRef();
    return (
        <>
            <Layout>
                <Layout>
                    <Header style={{
                        background : '#fff', 
                        border:'1px solid #edf2f7', 
                        position: 'sticky', 
                        top: 0, 
                        zIndex: 100, 
                        width: '100%',
                        ...(globalHelper?.isMobileScreen() && { padding : '0 12px' })
                    }}
                    >
                        <MasterHeader />
                    </Header>
                    <Content id="content-container" className='main-content' ref={contentRef}>
                        <div className='content'>
                            {children}
                        </div>
                    </Content>
                    {/* In Case Razer want footer */}
                    {/* <Footer id="footer-container" style={{background : '#282e41 '}}>                    
                        <div>
                            
                        </div>
                    </Footer> */}
                </Layout>
            </Layout>
        </>
    );
}

export default MasterLayout;