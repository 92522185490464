import React from 'react';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import { Avatar, Badge, Col, Divider, Image, Row, Space, Tag } from 'antd';
import { CheckOutlined, RightOutlined } from '@ant-design/icons';
import globalHelper from '../../../helpers/functions/global.helper';
import { Link } from 'react-router-dom';
import GreyContainer from '../../../helpers/components/grey_container';
import WhatToDoNext from '../components/what_to_do_next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import DynamicSvg from '../../../helpers/components/dynamic_svg';

const PaymentDateResultSuccess = ({ date_change }) => {
    const ticket = date_change?.ticket;
    return (
        <>
            <div className='mobile-padding-24' style={{ width:'100%'}}>
                <div style={{position:'relative'}}>
                    <EntranceMotion initialY={-100} duration={0.5}>
                        <Badge count={
                                <div>
                                    <EntranceMotion initialY={-50} duration={0.5} delay={0.2}>
                                        <Tag
                                        color={'green'} 
                                        style={{
                                            border:'none', 
                                            textAlign:'center', 
                                            borderRadius:'50%', 
                                            width:40, 
                                            height:40, 
                                            display:'flex', 
                                            justifyContent:'center',
                                            alignItems:'center',
                                        }}
                                        >
                                            <CheckOutlined style={{fontSize:20}} />
                                        </Tag>
                                </EntranceMotion>
                                </div>
                        }
                        offset={[-16, 105]}
                        >
                            <Image src='/cibanners/success-ticket.png' style={{maxWidth: (globalHelper?.isMobileScreen() ? 120 : 140)}} preview={false}/>
                        </Badge>
                    </EntranceMotion>
                    
                </div>

                <div style={{marginTop:12}}>
                    {/* <Lottie animationData={successJson} loop={false} style={{ height : 400, width: '100%', position: 'absolute', }}/> */}
                    <EntranceMotion initialY={-100} duration={0.5} delay={0.1}>
                        <div style={{width:'fit-content', margin: ('auto'), textAlign:'center'}}>
                            <div>
                                <span className='ticket-label ticket-title h1' style={{fontWeight:'bold',}}>
                                    Transaction Successful!
                                </span>
                            </div>

                            <Space direction='vertical' size={8}  style={{width:'100%'}} >
                                <div>
                                    <span style={{fontWeight:500, color:'var(--secondary-text-color)'}}>Your have changed date for you ticket</span>
                                </div>
                            </Space>
                        </div>
                    </EntranceMotion>
                    
                    <div style={{marginTop:12}}>
                        <Space wrap style={{justifyContent:'center'}}>
                            <div>
                                <EntranceMotion delay={0.1}>
                                    <Tag color='var(--secondary-button-color)' style={{color:'var(--secondary-text-color)'}}>
                                        {`Transaction No: ${ticket?.unique_no}`}
                                    </Tag>
                                </EntranceMotion>
                            </div>
                            
                            <div>
                                <EntranceMotion delay={0.4}>
                                    <Tag color='var(--secondary-button-color)' style={{color:'var(--secondary-text-color)'}}>
                                        {`New Visit Date: ${ticket?.visit_date}`}
                                    </Tag>
                                </EntranceMotion>
                            </div>
                            <div>
                                {
                                    ticket?.preference &&
                                    (
                                        <>
                                            <EntranceMotion delay={0.5}>
                                                <Tag color='var(--secondary-button-color)' style={{color:'var(--secondary-text-color)'}}>
                                                    {`Visit Time: ${ticket?.preference?.preference_group ? (`${ticket?.preference?.preference_group?.name} - `) : ''} ${ticket?.preference?.name}`}
                                                </Tag>
                                            </EntranceMotion>
                                        </>
                                    )
                                }
                            </div>
                        </Space>
                    </div>
                    
                    <Divider/>

                    <div>
                        {/* put initial date and new date */}
                        <Space size={20}>
                            <div>
                                <EntranceMotion>
                                    <div className="border-circle" style={{border : '2px solid var(--secondary-button-color)', borderRadius:'50%', padding:8}}>
                                        <Avatar size={80} src={
                                            <DynamicSvg image={`/cicons/calendar.svg`} size={'100%'} color={'var(--secondary-text-color)'}/>
                                        } 
                                        />
                                    </div>
                                    <div style={{marginTop:8,}}>
                                        <span style={{color:'var(--secondary-text-color)'}}>{(date_change?.initial_date)}</span>
                                    </div>
                                </EntranceMotion>
                            </div>
                            <div>
                                <div style={{width:120,borderBottom:'1px dashed var(--secondary-text-color)'}} />
                            </div>
                            <div>
                                <EntranceMotion delay={0.1}>
                                    <div className="border-circle" style={{border : '2px solid var(--main-color)', borderRadius:'50%', padding:8}}>
                                        <Badge count={
                                                <div>
                                                    <EntranceMotion initialY={-50} duration={0.5} delay={0.3}>
                                                        <Tag
                                                        color={'green'} 
                                                        style={{
                                                            border:'none', 
                                                            textAlign:'center', 
                                                            borderRadius:'50%', 
                                                            width:40, 
                                                            height:40, 
                                                            display:'flex', 
                                                            justifyContent:'center',
                                                            alignItems:'center',
                                                        }}
                                                        >
                                                            <CheckOutlined style={{fontSize:20}} />
                                                        </Tag>
                                                </EntranceMotion>
                                                </div>
                                        }
                                        offset={[-5, 75]}
                                        >
                                            <Avatar size={80} src={
                                                <DynamicSvg image={`/cicons/calendar.svg`} size={'100%'} color={'var(--main-color)'}/>
                                            } 
                                            />
                                        </Badge>
                                    </div>
                                    <div style={{marginTop:8,}}>
                                        <span style={{color:'var(--main-color)', fontWeight:500,}}>{(date_change?.new_date)}</span>
                                    </div>
                                </EntranceMotion>
                            </div>
                        </Space>
                    </div>

                    <Divider />
                    <div style={{width:'100%',}}>
                        <div>
                            <span style={{fontWeight:500, color:'var(--secondary-text-color)'}}>
                            Please upload your selfies here if not done already.
                            </span>
                        </div>

                        <div style={{marginTop:12,}}>
                            <Link to={`/ticket/face/${ticket?.unique_no}`}>
                                <GreyContainer className="grey-card" height={"100%"} padding={0}>
                                    <div style={{width:'100%', display:'flex', flexDirection:'column', height:'100%'}}>
                                        <div style={{padding: 24, textAlign:'start'}}>
                                            <div>
                                                <Space align='start' size={20}>
                                                    <div>
                                                        <EntranceMotion delay={0.1}>
                                                            <div>
                                                                <Image className='features-image' src='/cibanners/selfie-guide.png' preview={false} style={{width: globalHelper?.isMobileScreen() ? 60 : 100}} />
                                                            </div>
                                                        </EntranceMotion>
                                                    </div>
                                                    <div>
                                                        <Space style={{ color:'#4d4f5e' }}>
                                                            <div>
                                                                <span style={{fontSize: (globalHelper?.isMobileScreen() ? 16 : 20), fontWeight:'bold',}}>Upload Your Selfies</span>
                                                            </div>
                                                            <div>
                                                                <RightOutlined className='right-arrow' />
                                                            </div>
                                                        </Space>

                                                        <div>
                                                        <span style={{fontWeight:500, color : 'var(--secondary-text-color)', fontSize : (14), }}>
                                                            We use Facial AI for check-ins, remember to upload your selfies with the link provided in your email.
                                                        </span>
                                                        </div>
                                                    </div>
                                                    
                                                </Space>
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                </GreyContainer>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PaymentDateResultSuccess;