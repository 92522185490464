import { CaretDownFilled, DownOutlined, LoadingOutlined } from '@ant-design/icons';
import { Alert, Checkbox, Col, Form, Input, Row, Space, Spin, } from 'antd';
import moment from 'moment';
import React, { useEffect, useRef, useState }  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { set_purchase_detail } from '../../../actions/purchase.action';
import parkService from '../../../services/config/park.service';
import PurchaseTicketTypeUserList from './purchase_ticket_type_user_list';
import StepNoItem from './step_no_item';
import EntranceMotion from '../../../helpers/components/entrance_motion';
import PurchaseAddOn from './purchase_add_on';
import globalHelper from '../../../helpers/functions/global.helper';
import { set_purchase_error } from '../../../actions/purchase_error.action';
import PurchaseTicketDatePicker from './purchase_ticket_date_picker';
import { set_capacity, set_liability_waiver } from '../../../actions/ticket.action';
import PreferenceList from './preference_list';

const PurchaseParkApplicableTicketTypeItem = ({ park, ticket_type, }) => {
    const dispatch = useDispatch();
    const purchaseRedux = useSelector(state => state.purchase);
    const purchaseErrorRedux = useSelector(state => state.purchase_error);
    const ticketRedux = useSelector(state => state.ticket);
    const renderedRef = useRef();
    const ticketUserRef = useRef();
    const balloonRef = useRef();
    const preferenceRef = useRef();

    const is_remain_capacity = (ticketRedux?.is_capacity && ticketRedux?.remaining_capacity > 0) || (!ticketRedux?.is_capacity)

    // input ref
    const emailRef = useRef();
    const liabilityRef = useRef();

    const [state, setState] = useState({
        date_loading : false,
        loading : true,
        park_applicable_ticket_users : [],
        park_applicable_add_ons : [],
        advance_day_discount : [],
        peak_day_discount : [],
    })

    const changeDate = async (ds) => {
        //clear add ons
        dispatch(set_purchase_detail({add_ons : []}));
        changeDetail('visit_date', ds)
    }

    const checkCapacity = async (visit_date) => {
        const { park_id, preference_id, } = purchaseRedux;
        const capacityResponse = await parkService?.getCapacity({ visit_date, park_id, preference_id, });
        const { capacity, remaining_capacity, is_capacity, } = capacityResponse;
        dispatch(set_capacity(is_capacity, remaining_capacity));
        return { capacity, remaining_capacity, is_capacity, };
    }

    const changeDetail = (key, value) => {
        if(purchaseErrorRedux[key]){
            dispatch(set_purchase_error({[key] : false,}))
        }
       
        dispatch(set_purchase_detail({
            [key] : value
        }))
    }

    const fetchParkApplicableTicketTypeItems = async () => {
        setState(state => ({...state, loading : true}));
        const park_applicable_ticket_type_id = (ticket_type?.ParkApplicableTicketType?.park_applicable_ticket_type_id);
        const parkApplicableTicketUserResponse = await parkService.getParkApplicableTicketTypeitems({
            park_applicable_ticket_type_id,
            purchase_type_code : 'OL',
        });

        const { park_applicable_ticket_users, park_applicable_add_ons, advance_day_discount, peak_day_discount } = parkApplicableTicketUserResponse ?? {};
        setState(state => 
        ({...state, 
            park_applicable_ticket_users : park_applicable_ticket_users, 
            park_applicable_add_ons : park_applicable_add_ons,
            advance_day_discount : advance_day_discount,
            peak_day_discount : peak_day_discount,
            loading : false,
        }));
    }

    const onLiabilityWaiverChecked = async (is_checked) => {
        changeDetail('liability_waiver_checked', is_checked);

        //open modal
        dispatch(set_liability_waiver(is_checked));
    }

    const initializeDateChange = async () => {
        setState(state => ({...state, date_loading : true,}));
        // check capacity
        const { visit_date } = purchaseRedux;
        await checkCapacity(globalHelper?.parseDate(visit_date));

        //scroll to tickets
        if(ticketUserRef?.current){
            const headerHeight = document.querySelector('header')?.offsetHeight ?? 0;
            const topOffset = ticketUserRef.current.getBoundingClientRect()?.top + window.scrollY - (headerHeight);
            window.scrollTo({ top : topOffset, behavior: 'smooth' });
        }
        
        setTimeout(() => {
            setState(state => ({...state, date_loading : false,}));
        }, 500);
    }

    const scrollError = () => {
        const { visit_date, email, liability_waiver_checked, preference, } = purchaseErrorRedux;
        const headerHeight = document.querySelector('header')?.offsetHeight ?? 0;
        let topOffset;
        if(email){
            if(emailRef?.current){
                topOffset = emailRef?.current.getBoundingClientRect()?.top + window.scrollY - (headerHeight);
            }
        }

        if(liability_waiver_checked){
            if(liabilityRef?.current){
                topOffset = liabilityRef?.current.getBoundingClientRect()?.top + window.scrollY - (headerHeight);
            }
        }

        if(preference){
            if(preferenceRef?.current){
                topOffset = preferenceRef?.current?.getBoundingClientRect()?.top + window.scrollY - (headerHeight);
            }
        }

        // scroll
        if(topOffset){
            window.scrollTo({ top : topOffset, behavior: 'smooth' });
        }
    }

    useEffect(() => {
        if(ticket_type){
            fetchParkApplicableTicketTypeItems();
        }
    }, [ticket_type?.ticket_type_id,]);

    useEffect(() => {
        if(purchaseRedux?.visit_date){
            initializeDateChange();
        }
    }, [purchaseRedux?.visit_date])

    useEffect(() => {
        if(renderedRef?.current){
            const headerHeight = document.querySelector('header')?.offsetHeight ?? 0;
            const topOffset = renderedRef.current.getBoundingClientRect().top + window.scrollY - (headerHeight + 40);
            window.scrollTo({ top : topOffset, behavior: 'smooth' });
        }
    }, [renderedRef, state?.loading]);

    // error effect
    useEffect(() => {
        const { visit_date, email, liability_waiver_checked, preference, } = purchaseErrorRedux;
        if(email || liability_waiver_checked || preference){
            scrollError();
        }
    }, [purchaseErrorRedux])

    return (
        <>
            {
                state?.loading ? 
                (
                    <>
                        <div style={{display:'flex', alignItems:'center', 'justifyContent' : 'center', minHeight:200}}>
                            <LoadingOutlined style={{fontSize:20}} />
                        </div>
                    </>
                )
                :
                (
                    <>
                        <div ref={renderedRef}>
                            <div className='step-item' id='step_2'>
                                <EntranceMotion initialY={-100} duration={0.5} delay={0}>
                                    {/* Visit Date */}
                                    <div className='step-2'>
                                        <StepNoItem no={2} text={'Visit Date'} image={'/cibanners/step/date-picker.png'} emoji={<>📅</>} />
                                        <div style={{marginTop:12, padding:'0 12px'}}>
                                            {/* <PurchaseTicketDatePickerV2 park={park} changeDate={changeDate} disabledDate /> */}
                                            <Space style={{flexFlow:'wrap', width : '100%', }}>
                                                <div>
                                                    <PurchaseTicketDatePicker park={park} changeDate={changeDate} disabledDate />
                                                </div>
                                                {
                                                    (ticket_type?.ParkApplicableTicketType?.date_range && purchaseRedux?.visit_date) &&
                                                    (
                                                        <>
                                                            <EntranceMotion initialY={-50} duration={0.5}>
                                                                <Space>
                                                                    <div>
                                                                        <span className='purchase-visit-date' style={{color:'#8c8c8c'}}>to</span>
                                                                    </div>
                                                                    <div>
                                                                        <span className='purchase-visit-date'>
                                                                            {moment(globalHelper.parseDate(purchaseRedux?.visit_date))?.add(parseInt(ticket_type?.ParkApplicableTicketType?.date_range), 'days').format("DD/MM/YYYY")}
                                                                        </span>
                                                                    </div>
                                                                </Space>
                                                            </EntranceMotion>
                                                        </>
                                                    )
                                                }
                                            </Space>
                                        </div>
                                    </div>
                                </EntranceMotion>
                            </div>
                            <div ref={ticketUserRef} style={{minHeight: 'calc(100vh - var(--header-height))'}}>
                            {
                                // if date is selected
                                // if is capacity and remaining is more than 0
                                (purchaseRedux?.visit_date) &&
                                (
                                    <>
                                        {
                                            state?.date_loading ?
                                            (
                                                <>
                                                    <div style={{textAlign:'center', padding : 12, fontSize:20,}}>
                                                        <LoadingOutlined />
                                                    </div>
                                                </>
                                            )
                                            :
                                            (
                                                <>
                                                    <div className={`select-visit-date-first ${purchaseRedux?.visit_date ? 'selected' : ''}`}>
                                                        {/* Preference */}
                                                        <div ref={preferenceRef} className='step-item' id='step_25'>
                                                            <PreferenceList balloonRef={balloonRef} visit_date={purchaseRedux?.visit_date} ticket_type={ticket_type} />
                                                        </div>

                                                        <div ref={balloonRef} className='step-item' id='step_3'>
                                                            <EntranceMotion initialY={-100} duration={0.5} delay={0.1}>
                                                                <div className='step-3' style={{marginTop:48}}>
                                                                    <StepNoItem no={3} text={'Tickets'} image={'/cibanners/step/balloon.png'} />
                                                                    {
                                                                        !is_remain_capacity &&
                                                                        (
                                                                            <>
                                                                                <div style={{marginTop:8,}}>
                                                                                    <EntranceMotion>
                                                                                        <Alert type='error' message={"Ticket sold out"} description={'You may select another visit date'} style={{border:'none'}}/>
                                                                                    </EntranceMotion>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    }
                                                                    <div style={{...(!is_remain_capacity && ({ opacity : 0.3, pointerEvents : 'none', }))}}>
                                                                        <PurchaseTicketTypeUserList peak_day_discount={state?.peak_day_discount} advance_day_discount={state?.advance_day_discount} park_applicable_ticket_users={state?.park_applicable_ticket_users} />
                                                                    </div>
                                                                </div>
                                                            </EntranceMotion>
                                                        </div>

                                                        <div className='step-item' id='step_4'>
                                                            <PurchaseAddOn visit_date={purchaseRedux?.visit_date} ticket_type={ticket_type} />
                                                        </div>
                                                        
                                                        <div className='step-item' id='step_5'>
                                                            <EntranceMotion initialY={-100} duration={0.5} delay={0.3}>
                                                                <div className='step-5' style={{marginTop:48}}>
                                                                    <StepNoItem no={(ticketRedux?.is_add_on ? 5 : 4)} text={'Contact Info'} image={'cibanners/step/contact.png'} emoji={<>🤙</>}/>
                                                                    <div style={{marginTop:12, padding : (globalHelper.isMobileScreen() ? 0 : ('0 12px'))}}>
                                                                        <div>
                                                                            <Form
                                                                            layout='vertical'
                                                                            >
                                                                                <div>
                                                                                    <Form.Item label="Name">
                                                                                        <Input
                                                                                        value={purchaseRedux?.name}
                                                                                        onChange={(e) => changeDetail('name', e?.target?.value)}
                                                                                        placeholder='John Doe'
                                                                                        size='large'
                                                                                        style={{maxWidth:400, background : 'var(--secondary-button-color)', border : '1px solid transparent', }}
                                                                                        />
                                                                                    </Form.Item>
                                                                                </div>
                                                                                <div ref={emailRef}>
                                                                                    <Form.Item name={'email'} label="Email Address">
                                                                                        <Input
                                                                                        id="input_email"
                                                                                        {...purchaseErrorRedux?.email && ({ status : "error" })}
                                                                                        type='email' 
                                                                                        defaultValue={purchaseRedux?.email}
                                                                                        value={purchaseRedux?.email}
                                                                                        onChange={(e) => changeDetail('email', e?.target?.value)}
                                                                                        placeholder='test@example.com'
                                                                                        size='large'
                                                                                        style={{
                                                                                            maxWidth:400, 
                                                                                            ...purchaseErrorRedux?.email ? 
                                                                                            ({ background: '#ffeded' })
                                                                                            : ({ background : 'var(--secondary-button-color)', border : '1px solid transparent', })
                                                                                        }}
                                                                                        />
                                                                                    </Form.Item>
                                                                                </div>
                                                                            </Form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </EntranceMotion>
                                                        </div>
            
                                                        <div ref={liabilityRef} className='step-item' id='step_6'>
                                                            <EntranceMotion initialY={-100} duration={0.5} delay={0.3}>
                                                                <div className='step-6' style={{marginTop:48}}>
                                                                    <StepNoItem no={(ticketRedux?.is_add_on ? 6 : 5)} text={'Liability Waiver'} image={'cibanners/step/tnc.png'} />
                                                                    <div style={{marginTop:12, padding : (globalHelper.isMobileScreen() ? 0 : ('0 12px'))}}>
                                                                        <div style={{padding:12, border:'1.5px solid #cbd5e0', borderColor:'#cbd5e0', borderRadius:8, ...purchaseErrorRedux?.liability_waiver_checked && ({ borderColor : '#ff4d4f', background : '#ffeded' })}}>
                                                                            <Checkbox checked={purchaseRedux?.liability_waiver_checked} onChange={(e) => onLiabilityWaiverChecked(e?.target?.checked)}>
                                                                                <span>I have read this <a href={park?.tnc_link} target='_blank'>Liability Waiver</a>, fully understand its terms, understand that I have given up substantial rights by selecting this checkbox, and do so freely and voluntarily without any inducement.</span>
                                                                                {/* <span>I have read and agree on the <a href={park?.tnc_link} target='_blank'>Terms and Conditions</a></span> */}
                                                                            </Checkbox>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </EntranceMotion>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                            </div>
                        </div>
                    </>
                )
            }
            
        </>
    );
}

export default PurchaseParkApplicableTicketTypeItem;